@import 'styles/main.scss';

.wrapper {
  margin-top: 1rem;
}

.similar__wrapper {
  @extend %basicWrapper;
  padding-top: 3.9375rem;
  padding-bottom: 3.9375rem;
}
