@import 'styles/main.scss';

.main {
  text-transform: uppercase;
  font-family: $basicFont;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.7rem;
  color: #ffff;
  background-color: $basicGreen;
  padding: 0.5rem 1.375rem;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}