@import 'styles/main.scss';

.container {
  &__scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  &__flex {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
}

.content {
  width: 100%;
  background-color: $white;
  padding: 1.375rem 1rem 1rem 1rem;
}

.button__close {
  padding: 5px;
  margin-bottom: 1.0625rem;
  background-color: transparent;

  p {
    font-size: 0.875rem;
    color: $dark;
    font-weight: 800;
    margin-left: 0.75rem;
  }
}

.label__white {
  background-color: #fff;
}