@import 'styles/main.scss';

.slider__container {
  overflow: hidden;
}

.slider {
  width: 100%;
  position: relative;
  left: -0.875rem;
}

.slider__item {
  width: 24.125rem !important;
  height: 24.125rem;
  padding: 0.875rem;

  img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    background-color: $noPhoto;
  }
}

.slider__button__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.625rem;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-right: 0.875rem;
  min-height: 2.5rem;
}
.slider__button {
  width: 3rem;
  height: 3rem;
  padding: 0;
  border-radius: 3rem;
  background-color: #f2f2f2;
}

.no_photo__wrapper {
  width: 24.125rem;
  height: 24.125rem;
  margin-bottom: 2.5rem;
  margin-top: 0.875rem;

  img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    background-color: $noPhoto;
  }
}

@media (max-width: 768px) {
  .slider {
    left: -0.4rem;
    min-width: 29.375rem;
    max-width: 29.375rem;
  }

  .slider__item {
    width: 9.625rem !important;
    -ms-flex-preferred-size: 9.625rem;
        flex-basis: 9.625rem;
    height: 9.625rem;
    padding: 0.4375rem;
  }

  .no_photo__wrapper {
    width: 9.625rem;
    -ms-flex-preferred-size: 9.625rem;
        flex-basis: 9.625rem;
    height: 9.625rem;
    margin-bottom: 1.4375rem;
    margin-top: 0;
  }

  .slider__button__group {
    display: none;
  }
}