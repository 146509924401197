@import 'styles/main.scss';

.dropdown__container {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.dropdown__content {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
}