@import 'styles/main.scss';

.counter__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.counter__form {
  margin-right: 1.5rem;
}

.counter__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 9.5rem;
}

.input__wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 4.625rem;
          flex: 0 1 4.625rem;
  height: 2.5rem;
  border-top: 1px solid #b9babb;
  border-bottom: 1px solid #b9babb;
}

.input {
  width: 100%;
  height: 2.5rem;
  text-align: center;
}

.counter__button {
  border: 1px solid #b9babb;
  height: 2.5rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2.5rem;
          flex: 0 0 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: rgba(0, 0, 0, 0.26);
  font-size: 13px;
  font-weight: 500;

  &.inc {
    border-radius: 0px 4px 4px 0px;
  }

  &.dec {
    border-radius: 4px 0px 0px 4px;
  }
}

.counter__price {
  font-size: 1.25rem;
  line-height: 1.6875rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  .counter__wrapper {
    margin-top: 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
  }

  .counter__form {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}