@import 'styles/main.scss';

.container {
  &__scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    top: 0;
  }

  &__flex {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.content {
  width: 100%;
  max-width: 25rem;
  background-color: $white;
  padding: 1.375rem 1rem 2.5rem 1rem;
}

.modal__button__close {
  padding: 5px;
  background-color: transparent;
  margin-left: auto;

  p {
    font-size: 0.875rem;
    color: $dark;
    font-weight: 800;
    margin-left: 0.75rem;
  }
}

.modal__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 1rem;
}

.modal__title {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.5rem;
}

.box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  &__text {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 700;
  }
}

@media (max-width: 768px) {
  .container__flex {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .content {
    max-width: 100%;
  }
}
