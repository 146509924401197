@import 'styles/main.scss';

.input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  width: 100%;
  border-radius: 0.5rem;
  height: 3.5rem;
  padding: 0 1rem;

  &__error {
    border: 1px solid $errorColor;
  }
}

.message__error {
  font-size: 0.75rem;
  margin-top: 6px;
  color: red;
}

.input::-webkit-input-placeholder {
  color: rgba(38, 50, 56, 0.6);
  font-size: 1rem;
  font-weight: 600;
}

.input::-moz-placeholder {
  color: rgba(38, 50, 56, 0.6);
  font-size: 1rem;
  font-weight: 600;
}

.input:-ms-input-placeholder {
  color: rgba(38, 50, 56, 0.6);
  font-size: 1rem;
  font-weight: 600;
}

.input::-ms-input-placeholder {
  color: rgba(38, 50, 56, 0.6);
  font-size: 1rem;
  font-weight: 600;
}

.input::placeholder {
  color: rgba(38, 50, 56, 0.6);
  font-size: 1rem;
  font-weight: 600;
}

.button__submit {
  width: 100%;
  background-color: $dark;
  color: #fff;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: none;
  text-transform: uppercase;
}