@import 'styles/main.scss';

.input__wrapper {
  min-height: 3.5rem;
  border: 1px solid $dark;
  border-radius: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px 0.9375rem;
  position: relative;
  margin-top: 1.625rem;

  &:first-child {
    margin-top: 0;
  }

  &__address {
    padding-top: 1rem;
  }

  &__comment {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding-top: 1rem;
  }

  &.error {
    border: 1px solid $errorColor;
  }
}

.input__label {
  font-size: 0.75rem;
  font-weight: 500;
  color: $dark;
  position: absolute;
  top: -0.5625rem;
  left: 0.6875rem;
  background-color: $white;
  padding: 0 2px;
}

.input {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  &::-webkit-input-placeholder {
    font-weight: 400;
  }
  &::-moz-placeholder {
    font-weight: 400;
  }
  &:-ms-input-placeholder {
    font-weight: 400;
  }
  &::-ms-input-placeholder {
    font-weight: 400;
  }
  &::placeholder {
    font-weight: 400;
  }

  &__address {
    min-height: 5.3125rem;
  }

  &__comment {
    min-height: 5.3125rem;
  }
}

.input__error__message {
  font-size: 0.75rem;
  color: $errorColor;
  margin-top: 0.3125rem;
}

.total__cost {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.6875rem;
  margin-top: 1.625rem;
  margin-bottom: 1rem;
}

.button__submit {
  min-width: 9.375rem;
}
