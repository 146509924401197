@import 'styles/main.scss';

.card {
  width: 100%;

  &__category {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 1px;
    color: $basicGreen;
    margin-bottom: 1.4375rem;
  }

  &__date {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  &__title::first-letter {
    text-transform: uppercase;
  }
}

.location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  &__icon {
    margin-right: 0.4375rem;
    position: relative;
    top: 1px;
  }

  &__city {
    font-size: 0.875rem;
    line-height: 1.3rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
  }
}

.image {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;

  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.9375rem;
    margin-top: 2.4375rem;
  }

  &__container {
    width: 6.0625rem;
    height: 6.0625rem;
    background-color: $noPhoto;
  }
}

.price {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.9375rem;
}

.description {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 2.4375rem;
}

.button {
  &__group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  &__more {
    font-size: 0.8125rem;
    font-weight: 700;
    margin-right: 1.8125rem;
    letter-spacing: 0.46px;
  }

  &__more p {
    line-height: 1.4375rem;
    margin-right: 0.6875rem;
  }
}