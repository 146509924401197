@import 'styles/main.scss';

.loader {
  &__box {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  width: 4.125rem;
  height: 4.125rem;

  img {
    width: 100%;
  }
}
