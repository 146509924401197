@import 'styles/main.scss';

.card__list__column {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1.3125rem;
  margin-bottom: 1.3125rem;
}

.card__wrapper {
  background-color: $white;
  width: 100%;
  border-radius: 0.5rem;
  padding: 2.5rem 1.5rem;
}

.cards__no_result {
  background-color: $white;
  min-height: 7.5rem;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2.3125rem;
}

.card__list__tile {
  margin-bottom: 1.5rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 0.9375rem auto 0.9375rem auto;
  grid-template-columns: repeat(3, auto);
  grid-gap: 0.9375rem 0.9375rem;
}