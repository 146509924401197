@import 'styles/main.scss';

.page {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: $dark;
  background-color: $basicGray;
  padding-top: 7.5rem;
  &__content {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
}

.wrapper__footerAdd {
  @extend %basicWrapper;
  width: 100%;
  padding: 5px 0;
}
