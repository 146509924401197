@import 'styles/main.scss';

.wrapper {
  @extend %basicWrapper;
  padding-bottom: 3rem;
}

.title {
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 700;
  margin: 1rem 0 2rem 0;
}

.sub__title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.inner {
  max-width: 57.5rem;
}

.desc {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .title {
    font-size: 2rem;
    line-height: 2.3125rem;
  }
}
