@import 'styles/main.scss';

.title {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.input__wrapper__type {
  margin-bottom: 1rem;
}

.price__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.input__wrapper {
  margin-bottom: 1rem;
  min-height: 3.5rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px 0.9375rem;
  position: relative;

  &__select {
    padding: 0;
  }

  &__city {
    padding: 0;
  }

  &__type {
    padding: 0;
  }
}

.input__wrapper__price {
  width: 48%;
  margin-bottom: 1rem;
  min-height: 3.5rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px 0.9375rem;
  position: relative;
}

.input__wrapper__checkbox {
  margin: 1.4375rem 0 1.8125rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.input__label {
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: -0.5625rem;
  left: 0.6875rem;
  background-color: $basicGray;
  padding: 0 2px;
}

.checkbox__label {
  margin-left: 0.6875rem;
}

.input__icon {
  margin-right: 0.6875rem;
}

.input {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  &::-webkit-input-placeholder {
    font-weight: 400;
  }
  &::-moz-placeholder {
    font-weight: 400;
  }
  &:-ms-input-placeholder {
    font-weight: 400;
  }
  &::-ms-input-placeholder {
    font-weight: 400;
  }
  &::placeholder {
    font-weight: 400;
  }
}

.button {
  &__search {
    width: 100%;
    font-weight: 700;
    letter-spacing: 0.46px;
    margin-bottom: 1rem;
    line-height: 1.8rem;
  }

  &__reset {
    background-color: $gallery;
    width: 100%;
    color: $dark;
  }
}
