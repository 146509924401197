@import 'styles/main.scss';

.input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  width: 100%;
  border-radius: 0.5rem;
  height: 3.5rem;
  padding: 0 1rem;

  &__error {
    border: 1px solid $errorColor;
  }

  &__disabled {
    border: 1px solid $dark;
    color: $dark;
    cursor: not-allowed !important;
  }
}

.message__error {
  font-size: 0.75rem;
  margin-top: 6px;
  color: red;
}

.input::-webkit-input-placeholder {
  color: rgba(38, 50, 56, 0.6);
  font-size: 1rem;
  font-weight: 600;
}

.input::-moz-placeholder {
  color: rgba(38, 50, 56, 0.6);
  font-size: 1rem;
  font-weight: 600;
}

.input:-ms-input-placeholder {
  color: rgba(38, 50, 56, 0.6);
  font-size: 1rem;
  font-weight: 600;
}

.input::-ms-input-placeholder {
  color: rgba(38, 50, 56, 0.6);
  font-size: 1rem;
  font-weight: 600;
}

.input::placeholder {
  color: rgba(38, 50, 56, 0.6);
  font-size: 1rem;
  font-weight: 600;
}

.input__code__wrapper {
  margin-top: 1rem;
}

.button__back {
  font-size: 0.75rem;
  color: $basicGreen;
  margin-top: 6px;
}

.modal__button__more {
  width: 100%;
  color: $dark;
  background-color: #eeeeee;
  margin: 1rem 0;
}

.button__submit {
  width: 100%;
  background-color: $dark;
  color: #fff;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: none;
  text-transform: uppercase;
}

.auth__link {
  text-transform: uppercase;
  font-family: $basicFont;
  font-weight: 800;
  font-size: 0.9375rem;
  line-height: 1.7rem;
  color: $dark;
  background-color: #eeeeee;
  padding: 0.5rem 1.375rem;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 1rem;

  p {
    margin-left: 0.5rem;
  }
}