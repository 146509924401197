@import 'styles/main.scss';

.wrapper {
  @extend %basicWrapper;
  padding-bottom: 3rem;
}

.inner {
  max-width: 57.5rem;
}

.functional {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.title {
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 700;
  margin: 1rem 0 4rem 0;
}

.sub__title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.desc {
  margin-bottom: 2rem;
  line-height: 1.5rem;
}

.desc__important {
  margin-bottom: 1rem;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.list__item {
  position: relative;
  padding-left: 10px;
  margin-bottom: 0.8rem;
  line-height: 1.5rem;

  &::before {
    position: absolute;
    content: '';
    top: 7px;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: $basicGreen;
  }
}

.functional__list,
.web__list {
  margin-top: 1rem;
}

.navbot__box {
  margin-top: 3rem;
}

.admin__box {
  margin-top: 3rem;
}

.slogan__box {
  margin-top: 3rem;
}

.button__subscribe {
  margin-top: 2rem;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 1.7rem;
  background-color: $dark;
  color: $gallery;
  padding: 0.5rem 1.375rem;
  border-radius: 4px;
  display: flex;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: fit-content;
  font-size: 0.9375rem;
}

@media (max-width: 768px) {
  .title {
    font-size: 2rem;
    line-height: 2.3125rem;
  }
}
