@import 'styles/main.scss';

.backdrop {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 20;
}
