@import 'styles/main.scss';

.container {
  &__scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    position: relative;
    top: -100%;
    -webkit-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
  }

  &__scroll__visible {
    top: 0;
  }

  &__flex {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.content {
  width: 100%;
  background-color: $white;
  padding: 1.375rem 1rem 1rem 1rem;
}

.button__close {
  padding: 5px;
  background-color: transparent;

  p {
    font-size: 0.875rem;
    color: $dark;
    font-weight: 800;
    margin-left: 0.75rem;
  }
}

.heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 1rem;
}

.button__announcement {
  width: 100%;
  background-color: $dark;
  color: #fff;
  margin-bottom: 1rem;
  a {
    margin-left: 0.5rem;
    color: $white;
  }
}

.button__auth {
  background-color: #eeee;
  color: $dark;
  width: 100%;
}

.menu__link {
  text-transform: uppercase;
  font-family: $basicFont;
  font-weight: 800;
  font-size: 0.9375rem;
  line-height: 1.7rem;
  color: $dark;
  padding: 0.5rem 1.375rem;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 1rem 0;
}
