@import 'styles/main.scss';

.checkbox__hidden {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

.checkbox__styled {
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: transparent;
  width: 1.125rem;
  height: 1.125rem;
  -webkit-transition: background-color 0.3s ease-in, border 0.3s ease-in;
  -o-transition: background-color 0.3s ease-in, border 0.3s ease-in;
  transition: background-color 0.3s ease-in, border 0.3s ease-in;

  .svg {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in;
    -o-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
  }
}

.checkbox__hidden:checked + .checkbox__styled {
  border: 1px solid rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.6);

  .svg {
    opacity: 1;
  }
}
