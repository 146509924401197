@import 'styles/main.scss';

.section {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 7.625rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.products {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 35.25rem;
          flex: 0 1 35.25rem;
}

.button__forward {
  width: 100%;
  margin-top: 1rem;
  display: none;
}

.order__form {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 27.875rem;
          flex: 0 1 27.875rem;
  padding-top: 4.5rem;
}

@media (max-width: 768px) {
  .button__forward {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .order__form {
    padding-top: 0;
  }
}