@import 'styles/main.scss';

.comment__wrapper {
  background-color: $white;
  padding: 1rem 1rem 1.25rem 1.5rem;
  border-radius: 0.5rem;
  max-width: 47.5rem;
}
.heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1.25rem;

  &__avatar {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 2.5rem;
            flex: 0 0 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    overflow: hidden;
    margin-right: 1rem;
  }
  &__avatar img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  &__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  &__username {
    color: rgba(26, 26, 26, 0.7);
    margin-right: 1rem;
    line-height: 1.5rem;
    position: relative;
  }

  &__username:after {
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: rgba(26, 26, 26, 0.7);
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    right: -0.6rem;
  }

  &__location {
    color: rgba(26, 26, 26, 0.7);
    line-height: 1.5rem;
  }
}

.comment__text {
  color: rgba(0, 0, 0, 0.6);
  -webkit-hyphens: 1.5rem;
      -ms-hyphens: 1.5rem;
          hyphens: 1.5rem;
  padding-right: 2.3rem;
}