@import 'styles/main.scss';

.logo__wrapper {
  max-width: 9.375rem;
  height: auto;
  max-height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: auto;
  img {
    height: auto;
    max-height: 50px;
    width: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.logo__string {
  color: $basicGreen;
  font-size: 1rem;
}
