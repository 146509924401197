@import 'media';
@import 'mixins';
@import 'root';

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  cursor: default;
  @extend %scrollbar;
  font-family: $basicFont;
  font-size: 1rem;
  width: 100%;
}

section {
  position: relative;
}

a {
  display: inline-block;
  text-decoration: none;
}

// убирает цвет автокомплита у инпута
input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    font-size: 1rem;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
  }
}

textarea,
button,
input {
  font-family: $basicFont;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  outline: none;

  &[disabled] {
    cursor: default;
    opacity: 0.7;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

button {
  cursor: pointer;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

// для ya-share
.ya-share2__badge_more,
.ya-share2__link_more {
  opacity: 0 !important;
}

.ya-share2__title {
  font-size: 1rem !important;
  font-weight: 500;
  font-family: $basicFont;
  text-transform: capitalize;
}

.ya-share2__popup {
  min-width: 12.5rem;
}

.slick-track {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}