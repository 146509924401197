@import 'styles/main.scss';

.similar {
  &__wrapper {
    @extend %basicWrapper;
    padding-top: 3.9375rem;
    padding-bottom: 3.9375rem;
  }

  &__title {
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 1.5625rem;
  }

  &__list {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1rem;
  }

  &__card__wrapper {
    max-width: 47.5rem;
    padding: 1rem 1rem 1rem 0;
    -webkit-box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  }

  &__card__wrapper:last-child {
    -webkit-box-shadow: none;
            box-shadow: none;
  }

  &__button__more {
    background-color: transparent;
    padding: 0;
    margin-top: 3.6875rem;
  }

  &__button__more p {
    text-transform: none;
    margin-right: 1.5625rem;
    color: $dark;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
  }
}
