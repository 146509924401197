@import './root.scss';
%basicWrapper {
  max-width: 73.875rem;
  padding: 0 0.9375rem;
  margin: 0 auto;
}

%scrollbar::-webkit-scrollbar {
  width: 0.5%;
  min-width: 8px;
}

%scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

%scrollbar::-webkit-scrollbar-thumb {
  background: $basicGreen;
  border-radius: 3px;
}
