@import 'styles/main.scss';

.container {
  &__scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    top: 0;
  }

  &__flex {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.content {
  width: 100%;
  max-width: 25rem;
  background-color: $white;
  padding: 1.375rem 1rem 1rem 1rem;
  border-radius: 0.75rem;
}

.modal__button__close {
  padding: 5px;
  background-color: transparent;

  p {
    font-size: 0.875rem;
    color: $dark;
    font-weight: 800;
    margin-left: 0.75rem;
  }
}

.modal__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 1rem;
}

.modal__title {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.5rem;
}

.auth__link {
  text-transform: uppercase;
  font-family: $basicFont;
  font-weight: 800;
  font-size: 0.9375rem;
  line-height: 1.7rem;
  color: $dark;
  background-color: #eeeeee;
  padding: 0.5rem 1.375rem;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 1rem;

  p {
    margin-left: 0.5rem;
  }
}

@media (max-width: 768px) {
  .container__flex {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .content {
    max-width: 100%;
    border-radius: 0;
  }
}