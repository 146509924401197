@import 'styles/main.scss';

.breadcrumbs__wrapper {
  @extend %basicWrapper;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.breadcrumbs__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.breadcrumbs__svg {
  margin: 0 0.75rem;
}