@import 'styles/main.scss';

.wrapper {
  @extend %basicWrapper;
  margin-top: 1rem;
}

.catalog__title {
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.75rem;
}

.catalog__content {
  display: flex;
}

.catalog__filter__wrapper {
  flex: 0 0 16.875rem;
  margin-right: 7.625rem;
}

.catalog__filter {
  min-height: 324px;
  position: sticky;
  top: 7rem;
  padding-top: 1rem;
  padding-bottom: 4rem;
}

.cards {
  flex: 1 1 47.5rem;

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8125rem;
  }

  &__quantity {
    font-size: 1.25rem;
    line-height: 1.4375rem;
    font-weight: 700;
    max-width: 18.75rem;
  }

  &__sorting {
    min-width: 14.75rem;
  }

  &__button__modal {
    background-color: #ebebeb;
    color: $dark;
    margin-bottom: 1rem;
    width: 100%;
    display: none;
  }

  &__button__modal p {
    margin-left: 0.625rem;
  }
}

.cards__heading__right {
  display: flex;
  align-items: center;
  gap: 1.1875rem;
}
.view__control {
  display: flex;
  gap: 0.5rem;

  &__button {
    cursor: pointer;
  }
}

.pagination__wrapper {
  margin-bottom: 3.75rem;
}

.loader__wrapper {
  width: 100%;
  margin: 5rem 0;
}

@media (max-width: 768px) {
  .catalog__title {
    font-size: 2rem;
    line-height: 2.3125rem;
  }

  .catalog__filter__wrapper {
    display: none;
  }

  .cards__heading {
    margin-bottom: 1rem;
  }
  .cards__quantity {
    display: none;
  }

  .cards__sorting {
    width: 100%;
  }

  .cards__button__modal {
    display: flex;
  }

  .pagination__wrapper {
    margin-bottom: 3.125rem;
  }

  .cards__heading__right {
    width: 100%;
  }
  .view__control {
    display: none;
  }
}

.test {
  color: #00000066;
}
