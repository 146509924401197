@import 'styles/main.scss';

.header {
  background-color: $white;
  position: fixed;
  padding: 5px 0;
  top: 0;
  width: 100%;
  z-index: 5;

  &__shadow {
    -webkit-box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  }
}

.wrapper {
  @extend %basicWrapper;
}

.header__content {
  min-height: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.header__content__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__logo {
  margin-right: 2.5625rem;
}

.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.1rem;
}

.navbar__link {
  font-weight: 600;
  color: $dark;
  position: relative;
  padding: 3px 0;

  &:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 0;
    background-color: $basicGreen;
    bottom: 0;
    left: 0;
    -webkit-transition: width 0.3s ease-in;
    -o-transition: width 0.3s ease-in;
    transition: width 0.3s ease-in;
  }

  &:hover:after {
    width: 100%;
  }
}

.button {
  &__group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.5625rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  &__auth {
    background-color: #eeee;
    color: $dark;
    padding: 0.4rem 0.5rem;
    border-radius: 0.5rem;

    p {
      margin-left: 0.5rem;
    }
  }

  &__channel {
    padding: 0.4rem 0.5rem;
    border-radius: 0.5rem;
    background-color: $dark;
    color: #fff;
    a {
      margin-left: 0.5rem;
      color: $white;
    }
  }

  &__add {
    padding: 0.4rem 0.5rem;
    border-radius: 0.5rem;
    color: #fff;
    a {
      margin-left: 0.5rem;
      color: $white;
    }
  }

  &__dropdown {
    padding: 0.4rem 0.5rem;
    border-radius: 0.5rem;
    background-color: #eeee;
    color: $dark;
    p {
      margin-right: 0.4375rem;
    }
  }

  &__logout {
    width: 100%;
  }

  &__burger {
    padding: 6px 3px;
    border-radius: 0;
    background-color: #fff;
    display: none;
  }
}

@media (max-width: 768px) {
  .header {
    -webkit-box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  }

  .button {
    &__group {
      display: none;
    }

    &__burger {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }

  .navbar {
    display: none;
  }
}