@import 'styles/main.scss';

.input {
  resize: none;
  @extend %scrollbar;

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    min-width: 5px;
  }
}
