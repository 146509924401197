@import 'styles/main.scss';

.footer {
  background-color: $white;

  &__shadow {
    -webkit-box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);
  }
}
.wrapper {
  @extend %basicWrapper;
}

.footer__content {
  padding: 2.5rem 0 1.625rem 0;
}

.footer__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.button__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 0.56rem;
}

.button__channel {
  padding: 0.4rem 0.5rem;
  border-radius: 0.5rem;
  background-color: $dark;
  color: #fff;
  a {
    margin-left: 0.5rem;
    color: $white;
  }
}

.button__add {
  padding: 0.4rem 0.5rem;
  border-radius: 0.5rem;
  color: #fff;
  a {
    margin-left: 0.5rem;
    color: $white;
  }
}

.button__auth {
  background-color: #eeee;
  color: $dark;
  padding: 0.4rem 0.5rem;
  border-radius: 0.5rem;

  p {
    margin-left: 0.5rem;
  }
}

.footer__logo {
  margin-bottom: 3.25rem;
}

.footer__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.5rem;
}

.footer__row__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.nav__links__left {
  margin-right: 2rem;
}

.nav__links__right {
  margin-right: 2rem;
}

.nav__link {
  font-family: 'Montserrat', sans-serif;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  position: relative;
  color: $dark;

  &::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    background-color: $dark;
    bottom: 4px;
    left: 0;
  }
}

.schedule {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-left: auto;

  &__text {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
  }
}

.copyright {
  color: rgba(26, 26, 26, 0.5);
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1.5rem;
}

.social {
  margin-top: 7px;

  &__link {
    margin-top: 0.625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid #d3d3d3;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  &__link p {
    color: $dark;
    font-size: 0.875rem;
    font-weight: 600;
    margin-left: 0.5rem;
  }

  &__link__svg {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding: 0;
  }
  .footer__content {
    padding: 2.5rem 0.625rem 3.4375rem 2.25rem;
  }

  .footer__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .button__group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 1.5rem;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    max-width: 15.625rem;
  }

  .footer__logo {
    margin-bottom: 2rem;
  }

  .footer__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-bottom: 1rem;
  }
  .footer__row__bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .nav__links__right {
    margin-bottom: 1rem;
  }

  .auth__button__group {
    margin-bottom: 1.125rem;
  }

  .schedule {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-left: 0;
    &__text {
      max-width: 14.375rem;
    }
  }
}