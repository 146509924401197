@import 'styles/main.scss';

.container {
  height: 100%;
  background-color: $white;
  padding: 1rem;
  border-radius: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.image {
  width: 100%;
  height: 13.125rem;

  img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.0625rem;
  min-height: 1.2rem;

  &__icon {
    margin-right: 0.4375rem;
    position: relative;
    top: 1px;
  }

  &__city {
    font-size: 0.875rem;
    line-height: 1.3rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
  }
}

.card__title {
  margin-top: 1.0625rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.card__footer {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 1rem;
}

.price {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.button__more {
  font-size: 0.8125rem;
  font-weight: 700;
  margin-right: 1.8125rem;
  letter-spacing: 0.46px;

  p {
    line-height: 1.4375rem;
    margin-right: 0.6875rem;
  }
}