@import 'styles/main.scss';

.product__wrapper {
  width: 100%;
  border-bottom: 1px solid rgba(38, 50, 56, 0.23);
  padding-bottom: 1rem;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.5rem;
}

.info__left {
  height: 9.25rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 9.25rem;
          flex: 0 0 9.25rem;
  background-color: $noPhoto;

  img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.info__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 0.625rem;
}

.title {
  font-size: 1.25rem;
  line-height: 1.6875rem;
  font-weight: 700;

  &::first-letter {
    text-transform: uppercase;
  }
}

.tag__list {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
}

.tag {
  padding: 0.4375rem 0.625rem;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 600;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.04);
  &::first-letter {
    text-transform: uppercase;
  }
}

@media (max-width: 768px) {
  .info__left {
    height: 4.5rem;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 4.5rem;
            flex: 0 0 4.5rem;
  }

  .title {
    min-height: 5.0625rem;
  }
}