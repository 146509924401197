@import 'styles/main.scss';

.wrapper {
  @extend %basicWrapper;
  margin-top: 1rem;
  margin-bottom: 4.75rem;
}

.loader__wrapper {
  width: 100%;
  margin: 5rem 0;
}

.trade__title {
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 700;
  margin: 0 0 2rem 0;
}

@media (max-width: 768px) {
  .wrapper {
    margin-bottom: 5.875rem;
  }

  .trade__title {
    font-size: 2rem;
    line-height: 2.3125rem;
    margin: 1rem 0 2.125rem 0;
  }
}
