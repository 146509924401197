@import 'styles/main.scss';

.image__box {
  position: relative;
}

.image {
  max-width: 100%;
  width: 13.125rem;
  height: 13.125rem;

  img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.swiper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
}

.swiper__item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.dot__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  position: absolute;
  bottom: 0.625rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: rgba(38, 50, 56, 0.23);
  border: 1px solid #263238;

  &.active {
    background-color: #263238;
  }
}

.social__share {
  position: absolute;
  z-index: 2;
  top: 0.625rem;
  right: 0.625rem;
  border-radius: 1.875rem;
}