@import 'styles/main.scss';

.title__wrapper {
  @extend %basicWrapper;
  padding-top: 3.9375rem;
}

.title {
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 3.4375rem;
}

.slider__wrapper {
  @extend %basicWrapper;
  max-width: 81.875rem;
  padding: 0 4.9375rem;
  position: relative;
  padding-bottom: 3.9375rem;
}

.slider {
  width: 100%;
}

.slider__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slider__item {
  min-width: 15.5rem;
  max-width: 15.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

.slider__item__inner {
  border-radius: 0.5rem;
  -webkit-box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.slider__button {
  width: 3rem;
  height: 3rem;
  padding: 0;
  border-radius: 3rem;
  background-color: #f2f2f2;
  position: absolute;

  &.prev {
    top: 40%;
    left: 0;
  }

  &.next {
    top: 40%;
    right: 0;
  }
}
