@import 'styles/main.scss';

.comments {
  background-color: $basicGray;
}
.comments__wrapper {
  @extend %basicWrapper;
  padding-top: 3.25rem;
  padding-bottom: 6.375rem;
}

.comments__title {
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 2.875rem;
}

.comments__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1.8125rem;
}

.button__more {
  background-color: transparent;
  padding: 0;
  margin-top: 2rem;

  p {
    text-transform: none;
    margin-right: 1.5625rem;
    color: $dark;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
  }

  &__svg {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}