@import 'styles/main.scss';

.wrapper {
  @extend %basicWrapper;
}
.wrapper__slider {
  @extend %basicWrapper;
}

.detailContent__wrapper {
  padding-bottom: 7rem;
}

.detail {
  &__category {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 1px;
    color: $basicGreen;
    margin-bottom: 1.25rem;
  }

  &__date {
    font-size: 0.75rem;
    margin-bottom: 1.25rem;
  }

  &__title {
    font-size: 2.125rem;
    font-weight: 700;
    line-height: 2.5rem;
    margin-bottom: 1.25rem;
    max-width: 43.75rem;
  }
}

.location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.25rem;

  &__icon {
    margin-right: 0.4375rem;
    position: relative;
    top: 1px;
  }

  &__city {
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
  }
}

.price {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
}

.price__empty {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
}

.button {
  &__group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1rem;
  }

  &__buy {
    font-size: 0.8125rem;
    font-weight: 700;
    margin-right: 1.8125rem;
    letter-spacing: 0.46px;
    width: 13.25rem;
  }

  &__to__seller {
    font-size: 0.8125rem;
    font-weight: 700;
    margin-right: 1.8125rem;
    letter-spacing: 0.46px;
    line-height: 1.7rem;
    background-color: $gallery;
    color: $dark;
    padding: 0.5rem 1.375rem;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-transform: uppercase;
  }
}

.box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.wrapper__cardAdd {
  padding: 1.5rem 0 1rem 0.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  max-width: 25rem;
}

.desc {
  max-width: 47.0625rem;

  &__title {
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }

  &__text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.loader__wrapper {
  width: 100%;
  margin: 5rem 0;
}

@media (max-width: 768px) {
  .detailContent__wrapper {
    padding-bottom: 4.0625rem;
  }

  .desc {
    margin-top: 1.4375rem;
  }

  .wrapper__slider {
    padding-right: 0;
  }

  .button {
    &__group {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      width: 100%;
      margin-bottom: 1.25rem;
    }

    &__buy {
      margin-right: 0;
      width: 100%;
      margin-bottom: 1rem;
    }

    &__to__seller {
      margin-right: 0;
      width: 100%;
      margin-bottom: 0.75rem;
    }
  }

  .box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .wrapper__cardAdd {
    margin-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}